import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import LoginComponent from '../login/login';
import OtpComponent from '../otp/otp';
import DashboardComponent from '../dashboard/dashboard';
import CalenderComponent from '../calender/mycalender';
import LateComponent from '../late-alert/latealert';
import PatientLateAlert from '../patient-detail/patient-late-alert';
import TemplateList from '../template-list/templatelist';
import SearchPatient from '../search-patient/searchpatient';
import CreateTemplateList from '../create-template/create-template';
import ProfileComponent from '../profile/profile';
import ChangePassword from '../change-password/changepassword';
import PatientQueue from '../patient-queue/patientqueue';
import PastVisit from '../past-visit/pastvisit';
import AllPastVisit from '../past-visit/allpastvisits';
import CopyToVisit from '../copy-to-visit/copytovisit';
import CopyToVisitSS from '../copy-to-visit/copytovisitss';
import TestResults from '../test-results/test-results';
import TestResultPathology from '../test-result-pathology/test-result-pathology';
import TestResultRadiology from '../test-result-radiology/test-result-radiology';
import History from '../history/history';
import Ordersetlist from '../order-set-list/order-set-list';
import IntuitiveExclusion from '../intuitive/exclusion';
import IntuitiveWordVarients from '../intuitive/varients';
import IntuitiveSplitWord from '../intuitive/split-word';
import IntuitiveNegetive from '../intuitive/negetive';
import DiagnosisKeyword from '../intuitive/diagnosis-keyword';
import CreateOrderSet from '../create-order-set/create-order-set';
import SubjectiveComponent from '../patient-detail/subjective';
import ObjectiveComponent from '../patient-detail/objective';
import PlanPrescription from '../patient-detail/plan';
import AssessmentComponent from '../patient-detail/assessment';
import KeyFindingsComponent from '../patient-detail/keyfindings';
import PHR from '../phr/phr';
import SoapComponent from '../soap/soap.component.js';
import SoapListComponent from '../soap/soap.list.component.js';
import Referral from '../patient-detail/referral';
import TodaysvisitSoap from '../patient-detail/todaysvisitSoap';
import PlanTestAdviced from '../patient-detail/plantestadviced';
import PlanInstruction from '../patient-detail/planinstruction';
import ViewPrescription from '../patient-detail/view-prescription';
import ViewPrescriptionSS from '../patient-detail/view-prescriptionss';
import BasicComponent from '../patient-detail/basic';
import PrivateRoute from '../../utils/PrivateRoutes';
import HeaderComponent from '../../components/header/header';
import { headerService$, sessionTimeoutService$ } from '../../utils/rxjs-sharing';
import LS_SERVICE from '../../utils/localStorage';
import { MYHC_APPTYPE, LOADER_RED, PROFILE, APPS_VARIANTS, APPS_FEATURES_LIST, HOSPITALDEFAULTS, USERTYPES, is_chat_enabled } from '../../utils/constant';
import VcSounds from '../video-conferencing/vc-sounds';
import FloaterWindow from '../video-conferencing/floater-window';
import IncomingCall from '../video-conferencing/IncomingCall';
import CalenderComponentMHVC from '../calender/mycalendermhvc';
import API_SERVICE from '../../utils/apiService';
import TemplateSelect from '../template-select/template-select';
import VerifyPinComponent from '../verify-pin/verify-pin';
import SetPinComponent from '../save-pin/save-pin';
import PubNubComponent from '../../components/pubnub/pubnub'
import Ecg from '../ecg/ecg';
import Allergies from '../allergies/allergies';
import ProgressNotes from '../progress-notes/progress-notes';
import AppTimeout from '../app-timeout/app-timeout';
import PatientDiagnosisReport from '../patient-detail/diagnosis-report'; 

import PaediatricsDevGrowthComponent from '../patient-detail/paeditrics-dev-growth';
//import Paediatrics from '../paediatrics/paediatrics';
import VaccinationRecord from '../vaccination-record/VaccinationRecord';


import HomecareAllergies from '../homecare/allergies/allergies';
import HomecareVitals from '../homecare/vitals/vitals';
import InternalNotes from '../homecare/internalnotes/internalnotes';
import HomecareDetailComponent from '../homecare/detail/homecaredetails';
import HomecarePastVisit from '../homecare/pastvisit/pastvisit';
import CaseSummary from '../homecare/casesummary/casesummary';
import HomecareCarePlan from '../homecare/care-plan/careplan';

import Gynaecology from "../patient-detail/gynaecology";
// import InternalNotesNew from '../patient-detail/internal-notes';
// import soapsinglev2 from '../patient-detail/soapsinglev2';
import InternalNotesNew from '../patient-detail/internal-notes';
import soapsingle from '../patient-detail/soapsingle';
import SoapSingleParent from '../patient-detail/soap-single-parent';
import MyHcSpeechRecognition from'../speech-to-text/speech-recognition';
import FileViewer from '../../components/file-viewer/file-viewer';

// const LoginComponent = React.lazy(() => import('../login/login'));
// const OtpComponent = React.lazy(() => import('../otp/otp'));
// const DashboardComponent = React.lazy(() => import('../dashboard/dashboard'));
// const CalenderComponent = React.lazy(() => import('../calender/mycalender'));
// const LateComponent = React.lazy(() => import('../late-alert/latealert'));
// const TemplateList = React.lazy(() => import('../template-list/templatelist'));
// const SearchPatient = React.lazy(() => import('../search-patient/searchpatient'));
// const CreateTemplateList = React.lazy(() => import('../create-template/create-template'));
// const ProfileComponent = React.lazy(() => import('../profile/profile'));
// const ChangePassword = React.lazy(() => import('../change-password/changepassword'));
// const PatientQueue = React.lazy(() => import('../patient-queue/patientqueue'));
// const PastVisit = React.lazy(() => import('../past-visit/pastvisit'));
// const AllPastVisit = React.lazy(() => import('../past-visit/allpastvisits'));
// const CopyVisit = React.lazy(() => import('../copy-visit/copyvisit'));
// const TestResults = React.lazy(() => import('../test-results/test-results'));
// const TestResultPathology = React.lazy(() => import('../test-result-pathology/test-result-pathology'));
// const TestResultRadiology = React.lazy(() => import('../test-result-radiology/test-result-radiology'));
// const History = React.lazy(() => import('../history/history'));
// const Ordersetlist = React.lazy(() => import('../order-set-list/order-set-list'));
// const CreateOrderSet = React.lazy(() => import('../create-order-set/create-order-set'));
// const SubjectiveComponent = React.lazy(() => import('../patient-detail/subjective'));
// const ObjectiveComponent = React.lazy(() => import('../patient-detail/objective'));
// const PlanPrescription = React.lazy(() => import('../patient-detail/plan'));
// const TodayVisitComponent = React.lazy(() => import('../patient-detail/todayvisit'));
// const AssessmentComponent = React.lazy(() => import('../patient-detail/assessment'));
// const KeyFindingsComponent = React.lazy(() => import('../patient-detail/keyfindings'));
// const PHR = React.lazy(() => import('../phr/phr'));
// const SoapComponent = React.lazy(() => import('../soap/soap.component'));
// const Referral = React.lazy(() => import('../patient-detail/referral'));
// const TodaysvisitSoap = React.lazy(() => import('../patient-detail/todaysvisitSoap'));
// const PlanTestAdviced = React.lazy(() => import('../patient-detail/plantestadviced'));
// const PlanInstruction = React.lazy(() => import('../patient-detail/planinstruction'));
// const ViewPrescription = React.lazy(() => import('../patient-detail/view-prescription'));
// const BasicComponent = React.lazy(() => import('../patient-detail/basic'));
// const VerifyPinComponent = React.lazy(() => import('../verify-pin/verify-pin'));
// const SetPinComponent = React.lazy(() => import('../save-pin/save-pin'));

function App(props) {
  let _headerService = null;
  let _unlisten = null;

  const [isAutenticated, setIsAuthenticated] = useState(false);
  const [soapConfig, setSoapConfig] = useState(null);

  useEffect(() => {

    const is_authenticated = LS_SERVICE.get('is_authenticated');

    if (LS_SERVICE.has('soapConfig')) {
      setSoapConfig(LS_SERVICE.get('soapConfig'));
    }

    if (LS_SERVICE.has('soapConfigData')) {
      const { location } = props;
      const splitData = location.pathname.split('/');
      const reference_id = splitData[splitData.length - 1];
      const soapConfigData = LS_SERVICE.get('soapConfigData');
      let isSoapOrBasic = soapConfigData.find(scd => scd.reference_id === +reference_id);
      if (isSoapOrBasic !== undefined) {
        isSoapOrBasic = isSoapOrBasic.soapConfig;
        setSoapConfig(isSoapOrBasic);
      }
    }

    if (is_authenticated) {
      setIsAuthenticated(is_authenticated)
    }

    _headerService = headerService$.status().subscribe(success => {
      if (success.showHeader) {
        setIsAuthenticated(success.showHeader === 'true' ? true : false)
      }
      if (success.soapConfig) {
        setSoapConfig(success.soapConfig);
        LS_SERVICE.set('soapConfig', success.soapConfig); // THIS IS CURRENT IMPLEMENTATION    
      }
    })

    _unlisten = props.history.listen(async (location, action) => {
      if(LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin){
        // NO ACTION
      }
      else{
        if (location.pathname !== '/' && location.pathname !== '/otp' && location.pathname !== '/verify-pin') {
          const is_doctor = LS_SERVICE.get("user_type") == USERTYPES.doctor;

          let parms = {};
          if(is_doctor === false){
            parms = { doctor_id: LS_SERVICE.get('selectedDoctorIdAppoinement') || null };
          }
         
          var profile = await API_SERVICE.post(PROFILE({ is_doctor }), parms);
          if(is_doctor === false){
            if(profile?.data?.nurse_doctors.length > 0){
              LS_SERVICE.set('nurse_doctor_id', profile?.data?.nurse_doctors);
            }
          }
          if (profile?.data?.obsgynae == 1) {
            LS_SERVICE.set('isObsGynaeDoctor', 1);
          }
          if (profile?.data?.paediatric == 1) {
            LS_SERVICE.set('paediatric_doctor', 1);
          }
        }
      }
    });

    return () => {
      _headerService.unsubscribe();
      _unlisten();
    }
  }, []);

  return (
    <>
      <Suspense fallback={
        <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
          {LOADER_RED}
        </div>}>
        {isAutenticated ? (
          <>
            <HeaderComponent />
            <AppTimeout />
            {is_chat_enabled && (
              <PubNubComponent />
            )}
          </>
        ) : ''}
        <Switch>
          <Route exact path="/" component={LoginComponent} />
          <Route exact path="/admin" render={(props) => <LoginComponent {...props} loginType="admin"/>} />

          <Route exact path="/otp" component={OtpComponent} />
          <Route exact path="/verify-pin" component={VerifyPinComponent} />
          <Route exact path="/set-pin" component={SetPinComponent} />
          <main id="main">
            <MyHcSpeechRecognition />
            <PrivateRoute exact path="/dashboard" component={DashboardComponent} />
            <PrivateRoute exact path="/my-calendar" component={APPS_FEATURES_LIST[MYHC_APPTYPE]?.calendarPage ? CalenderComponent : CalenderComponentMHVC} />
            <PrivateRoute exact path="/late-alert" component={LateComponent} />
            <PrivateRoute exact path="/patient-late-alert" component={PatientLateAlert} />
            <PrivateRoute exact path="/template-list" component={TemplateList} />
            <PrivateRoute exact path="/patient-search" component={SearchPatient} />
            <PrivateRoute exact path="/create-template" component={CreateTemplateList} />
            <PrivateRoute exact path="/profile" component={ProfileComponent} />
            <PrivateRoute exact path="/patient/past-visit/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id/:openVisit?" component={PastVisit} />

            {/* Care Plan Tab Start From Here */}
            <PrivateRoute exact path="/patient/homecare/careplan/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id/:openVisit?" component={HomecareCarePlan} />
            {/* Care Plan Tab End Here */}
            <PrivateRoute exact path="/patient/past-visit-other/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={AllPastVisit} />
            <PrivateRoute exact component={CopyToVisit}
              path="/copy-visit/selected/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:qms_token_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={CopyToVisit}
              path="/template/select/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id([0-9]+)/:template_id" />
            <PrivateRoute exact component={CopyToVisitSS}
              path="/copy-visit/selectedss/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:qms_token_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={CopyToVisitSS}
              path="/template/selectss/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id([0-9]+)/:template_id" />
            <PrivateRoute exact path="/patient/test-results/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={TestResults} />
            <PrivateRoute exact path="/patient/history/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={History} />
            <PrivateRoute exact path='/soap-configuration' component={SoapComponent} />
            <PrivateRoute exact path='/soap-configuration-list' component={SoapListComponent} />
            <PrivateRoute exact path="/order-set-list" component={Ordersetlist} />
            <PrivateRoute exact path="/intuitive-exclusion" component={IntuitiveExclusion} />
            <PrivateRoute exact path="/intuitive-negetive" component={IntuitiveNegetive} />
            <PrivateRoute exact path="/intuitive-split-word" component={IntuitiveSplitWord} />
            <PrivateRoute exact path="/intuitive-varients" component={IntuitiveWordVarients} />
            <PrivateRoute exact path="/diagnosis-keyword" component={DiagnosisKeyword} />

            <PrivateRoute exact path="/create-order-set" component={CreateOrderSet} />
            <PrivateRoute exact component={KeyFindingsComponent}
              path="/patient/todays-visit/assessment/key-findings/list/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={PlanPrescription}
              path="/patient/todays-visit/plan/prescription/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={PlanTestAdviced}
              path="/patient/todays-visit/plan/tests-advised/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={PlanInstruction}
              path="/patient/todays-visit/plan/instructions/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact component={VaccinationRecord}
              path="/patient/todays-visit/plan/vaccination-record/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" />
            <PrivateRoute exact path="/patient/todays-visit/follow-up/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id([0-9]+)/:date"
              component={APPS_FEATURES_LIST[MYHC_APPTYPE]?.calendarPage ? CalenderComponent : CalenderComponentMHVC} />
            <PrivateRoute exact path="/patient/todays-visit/objective/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={ObjectiveComponent} />
            <PrivateRoute exact path="/patient/todays-visit/objective/paediatrics/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={PaediatricsDevGrowthComponent} />
            <PrivateRoute exact path="/patient/todays-visit/subjective/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={SubjectiveComponent} />
            <PrivateRoute exact path="/patient/todays-visit/assessment/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={AssessmentComponent} />
            <PrivateRoute exact path="/template/edit/:editid" component={CreateTemplateList} />
            <PrivateRoute exact path="/patient-queue/:id" component={PatientQueue} />
            <PrivateRoute exact path="/order-set/edit/:orderid" component={CreateOrderSet} />
            <PrivateRoute exact path="/patient/health-records/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={PHR} />
            <PrivateRoute exact path="/patient/view-test-result/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id([0-9]+)/:code" component={TestResultPathology} />
            <PrivateRoute exact path="/patient/view-radiology-result/:patient_id([0-9]+)/:code" component={TestResultRadiology} />
            <PrivateRoute exact path="/patient/todays-visit/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={APPS_VARIANTS[soapConfig]?.todayVisitPage ? TodaysvisitSoap : BasicComponent} />
            <PrivateRoute exact path="/patient/todays-visit/view-prescription/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={ViewPrescription} />
            
            <PrivateRoute exact path="/patient/todays-visit/referral/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Referral} />
            <PrivateRoute exact path="/ecg/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Ecg} />

            <PrivateRoute exact path="/allergies/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Allergies} />
            <PrivateRoute exact path="/template/select/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={TemplateSelect} />

            <PrivateRoute exact path="/patient/paediatrics/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={PaediatricsDevGrowthComponent} />
            <PrivateRoute exact path="/patient/vaccination-record/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={VaccinationRecord} />
            

            

            
            <PrivateRoute exact path="/patient/homecare/progress-notes/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={ProgressNotes} />
            <PrivateRoute exact path="/patient/homecare/pastvisit/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={HomecarePastVisit} />
            <PrivateRoute exact path="/patient/homecare/details/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={HomecareDetailComponent} />
            <PrivateRoute exact path="/patient/homecare/vitals/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={HomecareVitals} />
            <PrivateRoute exact path="/patient/homecare/allergies/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={HomecareAllergies} />
            <PrivateRoute exact path="/patient/homecare/internal-notes/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={InternalNotes} />
            <PrivateRoute exact path="/patient/homecare/ecg/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Ecg} />
            <PrivateRoute exact path="/patient/homecare/case-summary/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={CaseSummary} />

            <PrivateRoute exact path="/patient/todays-visit/objective/obs-gynae/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Gynaecology} />
            <PrivateRoute exact path="/patient/obs-gynae/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={Gynaecology} />

            <PrivateRoute exact path="/patient/diagnosis-report/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={PatientDiagnosisReport} />
            <PrivateRoute exact path="/patient/homecare/diagnosis-report/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={PatientDiagnosisReport} />
 
            {/* <PrivateRoute exact path="/patient/internal-notes-new/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={InternalNotesNew} />
            <PrivateRoute exact path="/patient/soappage2/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={soapsinglev2} /> */}
            <PrivateRoute exact path="/patient/internal-notes-new/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={InternalNotesNew} />
            <PrivateRoute exact path="/patient/soappage/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={soapsingle} />
            <PrivateRoute exact path="/patient/soapss/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={SoapSingleParent} />
            <PrivateRoute exact path="/patient/todays-visit/view-prescriptionss/:hospital_id([0-9]+)/:patient_id([0-9]+)/:reference_type([0-9]+)/:reference_id" component={ViewPrescriptionSS} />
          </main>
        </Switch>
      </Suspense>
      <FloaterWindow />
      <VcSounds />
      <IncomingCall />
      <FileViewer />
    </>
  );
}

export default withRouter(App);