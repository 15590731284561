// import axios from 'axios';
import React, { Component, createRef } from 'react';
import API_SERVICE from '../../utils/apiService';
import {
  HOSPITAL_CONFIG,
  LOADER_RED,
  SYNC_TOKEN,
  MYHC_APPTYPE,
  MHVCMARKETPLACE, USERTYPES,
  CALENDAR_PATIENT_LIST,
  ENCRYPTION_KEY
} from '../../utils/constant';
import Moment from 'react-moment';
import moment from "moment";
import LS_SERVICE from '../../utils/localStorage';
import AppHelpers from '../../utils/AppHelpers';
import Axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { DatePicker, Modal, Form, Input, Table, notification } from "antd";
// import { InfinityTable as Table } from 'antd-table-infinity';
import SearchIcon from '../../assets/images/ic-search.svg';
import ReactToPrint from "react-to-print";
import ExportJsonExcel from 'js-export-excel';
import PrintComponent from "./print-component";
import crypto from 'crypto';

class DashboardComponent extends Component {

  state = {
    slots: [],
    loading: false,
    hospitalsCount: 0,
    appointmentListModal: false,
    booking_list: [],
    total_bookings: 0,
    selected_hospital: null,
    appointment_load: false,
    currentPage: 1,
    todayDate: moment(),
    filter_start_date: moment().format('YYYY-MM-DD'),
    filter_end_date: moment().format('YYYY-MM-DD'),
    filter_search: '',
    showDownloadPrint: false,
    booking_status: 0,
    filter_type: {},
    sorterData: '',
  }

  constructor(props) {
    super(props);

    if (LS_SERVICE.has('nurse_homecare') && LS_SERVICE.get('nurse_homecare')) {
      this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
    }
    else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
      this.props.history.push('/template-list')
    }

    this.filterFormRef = createRef();
  }

  decryptColumn = (string) => {
    if (string == null || string == '') {
      return null;
    }
    const encryption_key = ENCRYPTION_KEY;
    const encryptionMethod = 'AES-256-CBC';
    const secret = encryption_key.substring(0, 32); // must be 32 char length
    const iv = secret.substring(0, 16);

    const decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
    // decryptor.setAutoPadding(false);
    const decryptedString = decryptor.update(String(string), 'base64', 'utf8') + decryptor.final('utf8');
    return decryptedString;
  }

  handleCancel = () => {
    this.setState({
      booking_list: [],
      total_bookings: 0,
      selected_hospital: null,
      appointment_load: false,
      currentPage: 1,
      filter_start_date: moment().format('YYYY-MM-DD'),
      filter_end_date: moment().format('YYYY-MM-DD'),
      filter_search: '',
      appointmentListModal: false,
      filter_type: {},
      sorterData: ''
    });
  }

  changeInputText = async (val) => {
    if (val.target.name == 'name') {
      this.setState({
        filter_search: val.target.value
      })
    }
  }

  changeDateValue = (val, type) => {
    if (type == 'startDate') {
      this.setState({
        filter_start_date: moment(val).format('YYYY-MM-DD')
      })
    }
    if (type == 'endDate') {
      this.setState({
        filter_end_date: moment(val).format('YYYY-MM-DD')
      })
    }
  }

  componentDidMount() {
    if (this.props.history.location.state != null) {
      const syncData = this.props.history.location.state.syncdata;
      let hospitalsCount = (syncData.slots.length > 0) ? syncData.slots.length : 0;
      this.setState({
        slots: syncData.slots,
        hospitalsCount: hospitalsCount
      });
    } else {
      const syncDataSlots = AppHelpers.getDashboardSlots();
      let showLoader = true;
      if (typeof syncDataSlots != 'undefined' && syncDataSlots != null) {
        let hospitalsCount = (syncDataSlots.length > 0) ? syncDataSlots.length : 0;
        this.setState({
          slots: syncDataSlots,
          hospitalsCount: hospitalsCount
        });
        showLoader = false;
      }
      
      this.getStats(showLoader);
    }
    // if (this.props.history?.location?.state) {
    //   LS_SERVICE.clear();
    //   headerService$.showHeader({ showHeader: 'false' });
    // }

    if (LS_SERVICE.get("user_type") == USERTYPES.nurse) {
      this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
    }
  }

  componentWillUnmount() {
    document.querySelector('div.ant-table-body')?.addEventListener('scroll', this.handleScroll, true);
  }

  async getStats(showLoader) {
    if (showLoader) {
      this.setState({
        loading: true
      });
    }
    const syncData = await API_SERVICE.post(SYNC_TOKEN, { staff_id: LS_SERVICE.get('staff_id') });

    let SLOTS = syncData.slots;
    if (typeof SLOTS != 'undefined' && SLOTS.length > 0) {
      for (let index = 0; index < SLOTS.length; index++) {
        const doctorObj = SLOTS[index].doctor;
        // console.log(doctorObj, "doc");
        doctorObj.id = this.decryptColumn(doctorObj.id);
        doctorObj.name = this.decryptColumn(doctorObj.name);
        doctorObj.first_name = this.decryptColumn(doctorObj.first_name);
        doctorObj.last_name = this.decryptColumn(doctorObj.last_name);
        doctorObj.middle_name = this.decryptColumn(doctorObj.middle_name);
        doctorObj.woodlands_email = this.decryptColumn(doctorObj.woodlands_email);
        doctorObj.woodlands_mobile = this.decryptColumn(doctorObj.woodlands_mobile);
        doctorObj.is_chat_enable = this.decryptColumn(doctorObj.is_chat_enable);
        doctorObj.is_enable_for_multiple_calling = this.decryptColumn(doctorObj.is_enable_for_multiple_calling);
        syncData.slots.doctor = doctorObj;
      }
    }

    if (typeof syncData.slots != 'undefined') {
      let hospitalsCount = 0;
      if (syncData.slots.length > 0) {
        hospitalsCount = syncData.slots.length;
      }
      AppHelpers.setDashboardSlots(syncData);
      this.setState({
        slots: syncData.slots,
        hospitalsCount: hospitalsCount
      });

      // if (syncData.slots.length > 0) {
      //   for (const hospital of syncData.slots) {
      //     this.showHospitalCard(hospital, true);
      //   }
      // }
    }
    if (showLoader) {
      this.setState({
        loading: false
      });
    }
  }

  // handleBasicOrSoap = (e, hospital_id) => {
  //   e.preventDefault();
  //   const PARAMS = {
  //     hospital_id,
  //     speciality_id: LS_SERVICE.get('speciality_id'),
  //     staff_id: LS_SERVICE.get('staff_id')
  //   }

  //   Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
  //   Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS))
  //     .then(success => {
  //       headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
  //       LS_SERVICE.set('soapConfig', success.data.data.opd_layout);
  //       this.props.history.push('patient-queue/' + hospital_id);
  //     })
  //     .catch(error => console.log(error))
  // }

  showHospitalCard(hospital, increaseCount) {
    let status = false;
    if (MYHC_APPTYPE === MHVCMARKETPLACE) {
      if (
        (hospital.appointments.total_opd !== undefined && hospital.appointments.total_opd !== null) ||
        (hospital.appointments.total_vc !== undefined && hospital.appointments.total_vc !== null)
      ) {
        status = true;
      }
    } else {
      if (
        (hospital.appointments.total !== undefined && hospital.appointments.total !== null) ||
        (hospital.appointments.forwarded !== undefined && hospital.appointments.forwarded !== null)
      ) {
        status = true;
      }
    }

    if (status && increaseCount) {
      this.setState({
        hospitalsCount: this.state.hospitalsCount + 1
      });
    }
    return status;
  }

  opdCards() {
    const columns = [
      {
        title: 'S.NO',
        dataIndex: 'sno',
        width: '5%',
      },
      {
        title: 'TYPE',
        dataIndex: 'type',
        width: '7%',
        filters: [
          {
            text: <span>OPD</span>,
            value: '2',
          },
          {
            text: <span>VC</span>,
            value: '1',
          },
        ],
        // onFilter: (value, record) => record.type.startsWith(value),
      },
      {
        title: 'PATIENT',
        dataIndex: 'patient',
        width: '13%',
      },
      {
        title: 'UHID',
        dataIndex: 'uhid',
        width: '15%',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        width: '11%',
        // defaultSortOrder: 'descend',
        sorter: true,
      },
      {
        title: 'TIME',
        dataIndex: 'time',
        width: '9%',
      },
      {
        title: 'MOBILE',
        dataIndex: 'mobile',
        width: '14%',
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        width: '20%',
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        width: '11%',
        className: 'status',
        filters: [
          {
            text: <span>Confirmed</span>,
            value: 1,
          },
          {
            text: <span>Pending</span>,
            value: 7,
          },
          {
            text: <span>Arrived</span>,
            value: 2,
          },
          {
            text: <span>Completed</span>,
            value: 5,
          },
          {
            text: <span>Missed</span>,
            value: 4,
          },
          {
            text: <span>Cancelled</span>,
            value: 3,
          },
          {
            text: <span>Rescheduled</span>,
            value: 8,
          }
        ],
      },

    ];

    const printColumn = [
      {
        title: 'S.NO',
        dataIndex: 'sno',
        width: '6%',
      },
      {
        title: 'TYPE',
        dataIndex: 'type',
        width: '8%',

      },
      {
        title: 'PATIENT',
        dataIndex: 'patient',
        width: '18%',
      },
      {
        title: 'UHID',
        dataIndex: 'uhid',
        width: '9%',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        width: '11%',
      },
      {
        title: 'TIME',
        dataIndex: 'time',
        width: '11%',
      },
      {
        title: 'MOBILE',
        dataIndex: 'mobile',
        width: '11%',
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        width: '20%',
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        width: '11%',
        className: 'status',
      },

    ];

    const { slots } = this.state;
    const { Search } = Input
    const handleClick = () => {
      const data = this.state.booking_list ? this.state.booking_list : '';//table data
      var option = {};
      let dataTable = [];
      if (data) {
        for (let i in data) {
          if (data) {
            let obj = {
              'S.NO': data[i].sno,
              'TYPE': data[i].type,
              'PATIENT': data[i].patient,
              'UHID': data[i].uhid,
              'DATE': data[i].date,
              'TIME': data[i].time,
              'MOBILE': data[i].mobile,
              'EMAIL': data[i].email,
              'STATUS': data[i].download_status == "Canceled" ? "Cancelled" : data[i].download_status,
            }
            dataTable.push(obj);
          }
        }
      }
      option.fileName = 'AppointmentList.xlsx'
      option.datas = [
        {
          sheetData: dataTable,
          sheetName: 'sheet',
          sheetFilter: ['S.NO', 'TYPE', 'PATIENT', 'UHID', 'DATE', 'TIME', 'MOBILE', 'EMAIL', 'STATUS'],
          sheetHeader: ['S.NO', 'TYPE', 'PATIENT', 'UHID', 'DATE', 'TIME', 'MOBILE', 'EMAIL', 'STATUS'],
        }
      ];

      var toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
    };

  

    return (
      
      <>
        <div id="new_hos_card" className='col-12'>

          {slots.map((slot, index) => (
            <div className="card dashboard-card shadow-boxs mb-4" key={index}>
              <div style={{cursor: 'pointer'}} className="card-body dashboard-card-body" onClick={e => this.handleDashboardNav(e, slot.id)}>
                <div className='row d-flex align-items-center'>
                  <div className='col-1'>
                    <span><img className='hospital_img' src={slot?.hospital_image} alt={""}></img></span>
                  </div>
                  <div className='col-4 pl-xl-4 pl-5'>
                    <div className='row'>
                      <div className='col-12'>
                        <span className='font-weight-medium'>{slot.name}</span>
                      </div>
                      <div className='col-12'>
                        <span className='timings'>
                          {slot.start_time != null ? <Moment parse="HH:mm A" date={slot.start_time} format="h:mm A" /> : null}
                          {slot.start_time != null && slot.end_time != null ? ' to ' : null}
                          {slot.end_time != null ? <Moment parse="HH:mm A" date={slot.end_time} format="h:mm A" /> : null}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='col-7'>
                    <div className='row'>
                      {MYHC_APPTYPE === MHVCMARKETPLACE ? 
                      <React.Fragment>
                        <div className='col-2'></div>
                        <div className='col-xl-2 col-lg-2 col-2 offset-2 offset-md-0 offset-lg-2 text-center'>
                          <div><span>OPD</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot?.total_worklist_opd !== null ? slot?.total_worklist_opd : 0}
                            </span>
                          </div>
                        </div>
                        <div className='col-xl-2 col-lg-2 col-2 text-center'>
                          <div><span>VC</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot.total_worklist_vc !== undefined ? slot.total_worklist_vc : 0}
                            </span>
                          </div>
                        </div>
                        <div className='col-xl-2 col-lg-2 col-2  text-center'>
                          <div><span>Completed</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot?.total_completed_appointments ? slot?.total_completed_appointments : 0}
                            </span>
                          </div>
                        </div>
                        {slot.is_doctor_homecare_active ? <div className='col-xl-2 col-lg-2 col-2  text-center'>
                          <div><span>Homecare</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot.total_homecare_appointments !== undefined ? slot.total_homecare_appointments : 0}
                            </span>
                          </div>
                        </div> : <div className='col-xl-2 col-lg-2 col-2  text-center'>
                          &nbsp;
                        </div>}
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className='col-xl-3 col-lg-2 col-2  text-center'> &nbsp; </div>
                        <div className='col-xl-3 col-lg-2 col-2  text-center'> &nbsp; </div>
                        <div className='col-xl-2 col-lg-2 col-2  text-center'>
                          <div><span>Appointments</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot.appointments.total !== null ? slot.appointments.total : 0}
                            </span>
                          </div>
                        </div>
                        <div className='col-xl-2 col-lg-2 col-2  text-center'>
                          <div><span>Arrived</span></div>
                          <div>
                            <span className='font-weight-medium size '>
                              {slot.appointments.forwarded !== undefined ? slot.appointments.forwarded : 0}
                            </span>
                          </div>
                        </div>
                      </React.Fragment>}

                      {/* <div className='col-xl-2 col-lg-2 col-2 col-md-3'>
                        <div className='d-flex justify-content-end'>
                          <a className='text-secondary' onClick={e => this.handleAppointmentList(e, slot.id)}>
                            <span><u>Total Appts</u></span>
                            <div className='font-weight-medium size pl-2 text-center'>
                              <u>{slot?.appointments?.total ? slot?.appointments?.total : 0}</u>
                            </div>
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ))}

        </div>


        {/* {slots.map((slot, index) => (
          <div id="hospital_card" className="col-md-4" key={index}>
            <div className="card hospital-card shadow-box">
              <div className="card-body dashboard-hospital-card">
                <div className='row'>
                  <div className='col-auto'>
                    <button className='no-style total_appts_button' onClick={e => this.handleAppointmentList(e, slot.id)}>
                      <div className='btn btn-outline-primary cus_pad small text-uppercase'>TOTAL APPTS</div>
                      <div className="text-center pt-1 text-secondary bold_appts">{slot?.appointments?.total ? slot?.appointments?.total : 0}</div>
                    </button>
                  </div>
                  <div className='col text-right'>
                    <h4>{slot.name}</h4>
                    <p>
                      &nbsp;
                      {slot.start_time != null ? <Moment parse="HH:mm A" date={slot.start_time} format="h:mm A" /> : null}
                      {slot.start_time != null && slot.end_time != null ? ' to ' : null}
                      {slot.end_time != null ? <Moment parse="HH:mm A" date={slot.end_time} format="h:mm A" /> : null}
                    </p>
                  </div>
                </div>
                <a href="!#" className="nostyle-link " onClick={e => this.handleDashboardNav(e, slot.id)}> </a>
                <div className="text-center pt-3">
                  {
                    MYHC_APPTYPE === MHVCMARKETPLACE
                      ?
                      <div className="row">
                        <div className={slot.is_doctor_homecare_active ? "col-3" : "col-4"}>
                          <div>OPD</div>
                          <div className="col-12 display-2">{slot?.total_worklist_opd !== null ? slot?.total_worklist_opd : 0}</div>
                        </div>
                        <div className={slot.is_doctor_homecare_active ? "col-3" : "col-4"}>
                          <div>VC</div>
                          <div className="col-12 display-2">{slot.total_worklist_vc !== undefined ? slot.total_worklist_vc : 0}</div>
                        </div>
                        {
                          slot.is_doctor_homecare_active
                            ?
                            <div className="col-3">
                              <div>HomeCare</div>
                              <div className="col-12 display-2">{slot.total_homecare_appointments !== undefined ? slot.total_homecare_appointments : 0}</div>
                            </div>
                            :
                            null
                        }
                        <div className="col-3">
                          <div>Completed</div>
                          <div className="col-12 display-2">{slot?.total_completed_appointments ? slot?.total_completed_appointments : 0}</div>
                        </div>
                      </div>
                      :
                      <div className="row">
                        <div className="col-6">
                          Appointments
                          <div className="col-12 display-2">{slot.appointments.total !== null ? slot.appointments.total : 0}</div>
                        </div>
                        <div className="col-6">
                          Arrived
                          <div className="col-12 display-2">{slot.appointments.forwarded !== undefined ? slot.appointments.forwarded : 0}</div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        ))} */}

        {/* ##########  Appointment List  Modal ######## */}
        <Modal
          title='Appointment List'
          visible={this.state.appointmentListModal}
          onCancel={() => this.handleCancel()}
          destroyOnClose={true}
          footer={false}
          //width="1340px"
          className='appointment_modal'
        >
          <Form
            className='emrfrm'
            ref={this.filterFormRef}
          >
            <div className='row d-flex align-items-center'>
              <div className='col-6'>
                <div className="form-group hasdata mb-0">
                  <input name="name" type="text" id="search_by" className="form-control" placeholder="Patient Name/Mobile/UHID" maxLength="100" autoComplete="off" onChange={this.changeInputText} />
                  {/* <img src={SearchIcon} alt="icon" className="dashboard_search_image" /> */}
                  <label className="required" htmlFor="search_by">Patient Name/Mobile/UHID</label>
                </div>
              </div>
              <div className='col-2'>
                <div className='antInputItem hasdata'>
                  <Form.Item
                    name="start_date"
                    className='mb-0 dashboard_popupdate'
                  >
                    <DatePicker
                      name="start_date"
                      format="DD MMM, YYYY"
                      defaultValue={this.state.todayDate}
                      placeholder='Start Date'
                      className='mb-0'
                      style={{
                        width: 100 + "%",
                        height: 44 + "px",
                      }}
                      onChange={date => this.changeDateValue(date, 'startDate')}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='col-2'>
                <div className='antInputItem hasdata'>
                  <Form.Item
                    name="end_date"
                    className='mb-0 dashboard_popupdate'
                  >
                    <DatePicker
                      name="end_date"
                      format="DD MMM, YYYY"
                      defaultValue={this.state.todayDate}
                      placeholder='End Date'
                      className='mb-0'
                      style={{
                        width: 100 + "%",
                        height: 44 + "px",
                      }}
                      onChange={date => this.changeDateValue(date, 'endDate')}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='col-2 text-left'>
                <button className="btn btn-outline-primary px-4 py-2 small text-uppercase" onClick={() => this.searchAppointment()}>SEARCH</button>
              </div>
            </div>
            <div className='mt-3 mb-3 px-0  appoint_list_container'>
              <Table
                columns={columns}
                dataSource={this.state.booking_list}
                loading={this.state.appointment_load}
                onChange={(pagination, filter, sorter) => this.onChangePage(pagination, filter, sorter)}
                pagination={false}
                scroll={{ y: 410 }}
              />
            </div>
            {this.state.showDownloadPrint ?
              <div className='row pt-4 '>
                <div className='col-12 text-center'>
                  <a className="btn btn-outline-secondary px-3 small text-uppercase mr-2" onClick={handleClick}>DOWNLOAD</a>
                  <ReactToPrint
                    trigger={() => (
                      <a className="btn btn-primary px-3 small text-uppercase ml-2" href="#">
                        PRINT
                      </a>
                    )}
                    content={() => this.componentRef}
                  />
                </div>
              </div>
              : null}
            <div style={{ display: "none" }}>
              <PrintComponent ref={(el) => (this.componentRef = el)} columns={printColumn} booking_list={this.state.booking_list} />
            </div>
          </Form>
        </Modal>
      </>
    )
  }

  handleScroll = (evt) => {
    // evt.preventDefault();
    const elem = document.querySelector('div.ant-table-body');
    let sendRequest = false;
    if (elem.offsetHeight + elem.scrollTop >= (elem.scrollHeight - 20)) {
      sendRequest = true;
    }
    if (!sendRequest || this.isLoadMoreReqSent) {
      return;
    }
    this.isLoadMoreReqSent = true;

    let { paginationData, currentPage } = this.state;
    let scrollTop = document.querySelector('div.ant-table-body')?.scrollTop;
    // let current_page = paginationData?.current_page+1;
    let total_pages = paginationData?.total_pages;
    let current_page = paginationData?.current_page + 1;
    if (currentPage <= total_pages) {
      var pagination = { current: current_page };
      var filter = this.state.filter_type ? this.state.filter_type : {};
      this.onChangePage(pagination, filter)
    }
    if (currentPage != total_pages && !this.showDownloadPrint) {
      this.setState({ showDownloadPrint: false });
    }
    if (currentPage == total_pages) {
      this.showDownloadPrint = true;
      this.setState({ showDownloadPrint: true });
    }
  }

  searchAppointment() {
    if (this.state.filter_start_date > this.state.filter_end_date && this.state.filter_start_date != 'Invalid date') {
      this.setState({
        appointment_load: false
      })
      notification.error({
        message: `Start date can't greater than end date`,
        placement: 'topRight'
      })
    } else if (this.state.filter_start_date == 'Invalid date' && this.state.filter_end_date == 'Invalid date') {
      this.setState({
        appointment_load: false
      })
      notification.error({
        message: `Date can't be empty`,
        placement: 'topRight'
      })
    } else {
      var pagination = { current: 1 };
      var filter = this.state.filter_type ? this.state.filter_type : {};
      this.setState({ showDownloadPrint: false, booking_status: 0, loading: false });
      this.onChangePage(pagination, filter);

    }
  }

  async onChangePage(pagination, filter, sorter, bookingStatus = '') {

    if (sorter == undefined) {
      sorter = {}
    }
    if (Object.entries(sorter).length != 0) {
      if (sorter.order == 'ascend') {
        await this.setState({ sorterData: 'ASC' })
      }
      if (sorter.order == 'descend') {
        await this.setState({ sorterData: 'DESC' })
      }
    }

    if (this.state.filter_start_date == 'Invalid date' && this.state.filter_end_date == 'Invalid date') {
      this.setState({
        appointment_load: false
      })
      notification.error({
        message: `Date can't be empty`,
        placement: 'topRight'
      })
    } else {
      if (filter) {
        this.setState({
          filter_type: filter,
          showDownloadPrint: false,
        })
      }
      if (!this.state.selected_hospital) {
        return;
      }
      pagination = (pagination.current == undefined) ? { current: 1 } : pagination;

      await this.setState({
        appointment_load: { indicator: LOADER_RED },
        currentPage: pagination.current
      })
      var PARAMS = {
        hospital_id: this.state.selected_hospital,
        page: pagination.current,
        booking_status: (bookingStatus) ? bookingStatus : this.state.booking_status,
        sort: this.state.sorterData == undefined || this.state.sorterData == '' ? 'DESC' : this.state.sorterData,
      }

      if (this.state.filter_start_date != 'Invalid date' && this.state.filter_end_date !== 'Invalid date') {
        PARAMS.from = this.state.filter_start_date;
        PARAMS.to = this.state.filter_end_date;
      } else if (this.state.filter_start_date != 'Invalid date' || this.state.filter_end_date == 'Invalid date') {
        PARAMS.from = this.state.filter_start_date;
        PARAMS.to = this.state.filter_start_date;
      } else if (this.state.filter_start_date == 'Invalid date' || this.state.filter_end_date != 'Invalid date') {
        PARAMS.from = this.state.filter_end_date;
        PARAMS.to = this.state.filter_end_date;
      }
      if (this.state.filter_search && this.state.filter_search.trim() != '') {
        PARAMS.search = this.state.filter_search
      }
      if (filter?.status?.length > 0) {
        PARAMS.booking_status = filter?.status;
        this.setState({ booking_status: filter?.status });
      }
      if (filter?.status == null) {
        PARAMS.booking_status = 0;
        this.setState({ booking_status: [] });
      }
      PARAMS.consult_type = [];
      if (filter?.type?.length > 0) {
        PARAMS.consult_type = filter?.type
      }

    }
    Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS))
      .then(success => {
        let data = (pagination.current == 1) ? [] : this.state.booking_list;
        let i = (pagination.current == 1) ? 1 : this.state.booking_list.length + 1;
        let newdata = [];
        for (let booking_data of success.data.appointments) {
          newdata.push({
            key: i,
            sno: i,
            type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
            patient: booking_data?.patient?.full_name,
            uhid: booking_data?.booking_code ? booking_data?.booking_code : '-',
            date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
            time: booking_data.booking_time ? moment(booking_data.booking_date + ' ' + booking_data.booking_time).format('h:mm A') : '-', //'10:20 AM',
            mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
            email: booking_data?.patient?.email ? booking_data?.patient?.email : '-',
            status: booking_data?.booking_status_code == 3 ? <span className='text-danger'>Cancelled</span> : booking_data.booking_status,
            download_status: booking_data.booking_status
          })
          i++;
        }
        if (i <= 10) {
          this.setState({ showDownloadPrint: true })
        }
        if (newdata.length == 0 && data.length == 0) {
          this.setState({ showDownloadPrint: false })
        }
        this.setState({
          booking_list: [
            ...data,
            ...newdata
          ],
          paginationData: success.data?.pagination,
          total_bookings: success.data?.pagination?.total || data.length,
          appointment_load: false,
          //  showDownloadPrint: data.length == 0 && newdata.length == 0 ? false : true
        })
        this.isLoadMoreReqSent = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoadMoreReqSent = false;
      })

  }

  noHospitals() {
    return (
      <>
        {this.state.loading ? <div className="col-md-12 text-center">
          {LOADER_RED}
        </div> : <div className="col-md-12">
          <a href="#" className="nostyle-link">
            <div className="card hospital-card shadow-box">
              <div className="card-body">
                <div className="text-center">
                  <h4>No hospitals associated with {LS_SERVICE.get('user_type') == USERTYPES.nurse ? 'nurse' : 'doctor'}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>}
      </>
    )
  }

  handleDashboardNav = (e, hid) => {
    e.preventDefault();
    LS_SERVICE.delete('activeTab');
    this.props.history.push(`/patient-queue/${hid}`);
  }

  handleAppointmentList = (e, hid) => {
    e.stopPropagation();

    this.setState({
      appointmentListModal: true,
      appointment_load: { indicator: LOADER_RED },
    })

    const PARAMS = {
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      hospital_id: hid,
      booking_status: 0,
      page: 1,
    }

    Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS))
      .then(success => {
        var data = [];
        var i = 1;
        for (var booking_data of success.data.appointments) {
          data.push({
            key: i,
            sno: i,
            type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
            patient: booking_data?.patient?.full_name,
            uhid: booking_data?.booking_code ? booking_data?.booking_code : '-',
            date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
            time: booking_data.booking_time ? moment(booking_data.booking_date + ' ' + booking_data.booking_time).format('h:mm A') : '-',
            email: booking_data?.patient?.email ? booking_data?.patient?.email : '-',
            mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
            status: booking_data?.booking_status_code == 3 ? <span className='text-danger'>Cancelled</span> : booking_data.booking_status,
            download_status: booking_data.booking_status
          })
          i++;
        }
        this.setState({
          booking_list: data,
          paginationData: success.data?.pagination,
          total_bookings: success.data?.pagination?.total || data.length,
          selected_hospital: hid,
          appointment_load: false,
          currentPage: 1,
          showDownloadPrint: data.length == 0 ? false : success.data?.pagination?.total <= 10 ? true : false
        },
          () => {
            this.setState({ todayDate: moment() });
            this.isLoadMoreReqSent = false;
            document.querySelector('div.ant-table-body')?.addEventListener('scroll', this.handleScroll, true);
          })
      })
      .catch(error => {
        console.log(error)
        this.isLoadMoreReqSent = false;
      })


  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <div className="emr-dashboard mt-2" id="dashboard_data">

              <section className="section">
                {/* {this.state.hospitalsCount > 0 ? <h2 className="header mb-2">Appointments</h2> : null} */}
                {this.state.hospitalsCount > 0 ? <div className='col-12 mb-3'>
                  <h6 className='text-secondary  mb-0'>OPD Appointments</h6>
                </div> : null}
                <div className='col-12'>
                  <div className="row">
                    {this.state.hospitalsCount > 0 && !this.state.loading ? this.opdCards() : this.noHospitals()}
                  </div>
                </div>
                
              </section>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardComponent;
